export default function setOfferSeeMap() {
    const buttons = document.querySelectorAll('[data-offer-see-map]');
    if (!buttons) return;

    buttons.forEach(item => {
        item.addEventListener('click', (event) => {
            const { currentTarget } = event;
            const popupID = currentTarget.getAttribute('data-offer-see-map');

            if (popupID) {
                const popupEl = document.getElementById(`js-map-iframe-popup-${popupID}`);
                if (popupEl) {
                    popupEl.classList.add('is-active');
                }
            }
        })
    })
}