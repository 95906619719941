class NewsNavigation {
    constructor(el) {
        if (!this.set(el)) return;
        this.init();
    }

    set(el) {
        if (!el) return false;

        this.root = el;

        return true;
    }

    init() {
        this.setNav();
    }

    setNav() {
        window.scrollTo({
            top: this.root.offsetTop - 100,
            behavior: 'smooth',
        })
    }
}

export default function initNewsNavigation() {
    const sections = document.querySelectorAll('[data-news-navigation]');    
    if (!sections) return;

    sections.forEach(item => new NewsNavigation(item));
}