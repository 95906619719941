import { readCookie } from './../helpers/cookies-helper';

export default function updateUrl() {
    if (isHomePage()) {
        let cookie = readCookie('user_localization');
        if (cookie && cookie != '36') {
            window.location.hash = loc[cookie];
        }
        else {
            history.pushState(null, '', window.location.pathname + window.location.search);
        }
    }
}

function isHomePage() {
    const currentUrl = window.location.origin + window.location.pathname;
    let homeUrl = window.location.origin + '/';
    return currentUrl === homeUrl;
}