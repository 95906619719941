import { multipleSelect } from 'multiple-select-vanilla';

export default function initMultiselects() {
    const selects = document.querySelectorAll(
        '.js-cf-form .js-multiselect-input'
    );
    if (!selects) return;

    selects.forEach(function (node) {
        const i18n = JSON.parse(node.getAttribute('data-i18n'));

        multipleSelect(node, {
            selectAll: false,
            showClear: true,
            renderOptionLabelAsHtml: true,
            formatAllSelected() {
                return i18n.format_all_selected;
            },
            formatCountSelected(count, total) {
                let countSelected = i18n.format_count_selected.replace('${count}', count);
                countSelected = countSelected.replace('${total}', total)

                return countSelected;
            },
            formatNoMatchesFound() {
                return i18n.format_no_matches_found;
            },
            formatOkButton() {
                return i18n.format_ok_button;
            },
            formatSelectAll() {
                return i18n.format_select_all;
            },
        });
    });
}
