import AOS from 'aos';
import initAnimationClasses from './aos-animations/aos-animations';
import setSectionIntersect from './components/_nav-links';
import initNewsNavigation from './components/_news-navigation';
import setOfferSeeMap from './components/_offer-see-map';
import initFormFiles from './form/_form-file';
import initMultiselects from './form/_form-multiselect';
import initFileLinks from './helpers/file-links';
import initFormSelects from './helpers/form-inputs';
import updateUrl from './helpers/update-url';

var documentReady = function () {
    initFormSelects();
    initFormFiles();
    initMultiselects();
    initFileLinks();
    initAnimationClasses();
    initNewsNavigation();
    setSectionIntersect();
    updateUrl();
    setOfferSeeMap();

    AOS.init({
        once: true,
        disable: window.innerWidth < 768,
    });

    const observer = new ResizeObserver(entries => {
        for (let entry of entries) {
            AOS.refresh();
        }
    });
    const targetElement = document.body; // Możesz wskazać dowolny element
    observer.observe(targetElement);
};

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener('DOMContentLoaded', documentReady);
}
