export default function setSectionIntersect() {
    const sections_anchors = document.querySelectorAll('.section__anchor');
    const sections = document.querySelectorAll('section');

    let anchor_list = [];
    const menu_items = document.querySelectorAll('.js-nav-menu-link');
    menu_items.forEach(item => {
        let href = item.getAttribute('href');
        if (href.startsWith('#')) {
            href = href.substring(1);
            anchor_list.push(href);
        }
    });

    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && entry.intersectionRatio > 0) {
                    const sectionId = entry.target.id;
                    if (sectionId) {
                        window.history.replaceState(
                            null,
                            null,
                            `#${sectionId}`
                        );
                    }
                }
            });
        },
        {
            root: null,
            rootMargin: '0px 0px -85% 0px',
            threshold: 0,
        }
    );

    sections_anchors.forEach(section => {
        if (
            !section.id.startsWith('block_') &&
            !section.id.startsWith('custom_') &&
            anchor_list.includes(section.id)
        ) {
            observer.observe(section);
        }
    });

    window.addEventListener('scroll', () => {
        if (window.scrollY < 300) {
            const url = new URL(window.location);
            url.hash = '';
            window.history.replaceState(null, null, url);
        }
    });

    window.addEventListener('load', () => {
        const hash = window.location.hash;
        if (hash) {
            setTimeout(() => {
                const element = document.querySelector(hash);
                if (element) {
                    element.scrollIntoView({ behavior: 'instant' });
                }
            }, 100);
        }
    });
}
